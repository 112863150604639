body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
}

#title {
    float: left;
}

#loggedin {
    text-align: right;
}

#navigation {
    height: 42px;
}

#back,
#logout {
    background-color: black;
    color: white;
    outline: none;
}

#back {
    margin-right: 20px;
}

#devices {
    display: none;
}

#wall {
    display: none;
}

.screenshot {
    border: 2px solid transparent;
    height: 72px;
    width: 128px;
}
.screenshot.border {
    border: 2px solid #dc3545!important;
    border-radius: 2px;
}

.bold {
    font-weight: bold;
}

.versiontext {
    font-size: 40%;
    margin-left: 10px;
}

.warning {
    color: orange;
    font-weight: bold;
}

.error {
    color: #dc3545;
    font-weight: bold;
}

table.dataTable {
    border-collapse: collapse;
}

table.dataTable th,
table.dataTable td {
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px!important;

}

#devices table.dataTable:first-child {
    font-size: 80%;
}

table.dataTable tbody tr.odd {
    background-color: #F5F5F5;
}

div.dataTables_filter {
    float: right;
}

.tableheader {
    margin-top: 20px;
}

td.highlight {
    background-color: rgb(248, 215, 218)!important;
}

tr.extra > td {
    padding: 0!important;
}

td.details-control::before {
    top: 9px;
    left: 4px;
    height: 14px;
    width: 14px;
    display: block;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 15px;
    content: '+';
    background-color: #31b131;
}
td.slave {
    background-color: #c1e3ff;
}
td.slave::before {
    visibility: hidden;
}

td.details-control.shown::before {
    background-color: #d33333;
    content: '-';
}
td.details-control.shown {
    background-color: #c1e3ff;
}

.wall table.dataTable {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.led {
    height: 72px;
    width: 128px;
    overflow: hidden;
}

.led img {
    -webkit-transform: scale(13);
    -moz-transform: scale(13);
    -o-transform: scale(13);
    -ms-transform: scale(13);
    transform: scale(13);
    transform-origin: top left;
    margin-top: -25px;
    margin-left: -25px;
}

#screenshotinterval {
    margin-right: .5em;
    width: 60px;
}

.interval {
    padding-left: 0;
}

.inline {
    display: inline;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    width: 200px;
}

.inline select {
    background-color: transparent;
    display: inline;
    width: 60px;
    border: 1px solid #aaa;
    margin: 0 5px 0;
    padding: 4px;
}


/* overrides */
div.dataTables_wrapper div.dataTables_length select {
    width: 60px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    margin: 0;
    padding: 0;
}
.invalid-feedback {
    margin-left: 0!important;
}
.form-check-inline {
    margin-right: 7px;
}
.page-item .page-link {
    margin: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link,
.page-item:first-child .page-link:hover {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
}
.page-item:last-child .page-link,
.page-item:last-child .page-link:hover {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}
div.dataTables_paginate {
    margin-bottom: 20px!important;
}
div.dataTables_length {
    margin-right: 30px;
}